import React from "react";
import PropTypes from "prop-types";

import KeystoreCard from "./KeystoreCard";
import KeystoreLockDialog from "./KeystoreLockDialog";
import KeystoreEditorDialog from "./KeystoreEditorDialog";

class WebKeystore extends React.Component {
  state = {
    isKeystoreLockDialogOpen: false,
    isKeystoreEditDialogOpen: false
  };
  getKeystoreContext(keystore) {
    if (!keystore || keystore.data === null) {
      return {
        status: "empty",
        title: "You have no keys",
        subheader:
          "If you want to use keys generated by another application use import. If you want to generate a keystore for the first time, click create.",
        passwordPrompt: ""
      };
    }

    if (typeof keystore.data === "string") {
      return {
        status: "locked",
        title: "You have encrypted keys",
        subheader:
          "You will need to unlock your keys with your password before they can be used. You can export them while they are locked, but not after you unlock them.",
        passwordPrompt:
          "Enter the password used when the keystore was created or last locked."
      };
    }

    if (typeof keystore.data === "object") {
      return {
        status: "unlocked",
        title: "You have keys",
        subheader:
          "Your keys are ready for use. If you wish to reuse these keys in another application, you must lock this keystore and then export it.",
        passwordPrompt: "Enter a password to lock this keystore."
      };
    }
  }

  onOpenKeystoreLockDialog = () => {
    this.setState({
      isKeystoreLockDialogOpen: true
    });
  };

  onOpenKeystoreEditDialog = () => {
    this.setState({
      isKeystoreEditDialogOpen: true
    });
  };

  doToggleKeystore = password => {
    this.setState({
      isKeystoreLockDialogOpen: false
    });
    this.props.doToggleKeystore(password);
  };

  render() {
    const {
      doImportKeystore,
      doDeleteKeystore,
      doCreateWalletKeystore,
      doUpdateKeystore
    } = this.props;
    const keystore = this.props.keystore.keystore;
    const {
      status,
      title,
      subheader,
      passwordPrompt
    } = this.getKeystoreContext(keystore);
    const { isKeystoreLockDialogOpen, isKeystoreEditDialogOpen } = this.state;
    return (
      <React.Fragment>
        <KeystoreCard
          status={status}
          title={title}
          subheader={subheader}
          keystore={keystore}
          doImportKeystore={doImportKeystore}
          doDeleteKeystore={doDeleteKeystore}
          doCreateWalletKeystore={doCreateWalletKeystore}
          onOpenKeystoreLockDialog={this.onOpenKeystoreLockDialog}
          onOpenKeystoreEditDialog={this.onOpenKeystoreEditDialog}
        />
        <KeystoreLockDialog
          message={passwordPrompt}
          open={isKeystoreLockDialogOpen}
          keystoreState={status}
          doToggleKeystore={this.doToggleKeystore}
          onClose={() => {
            this.setState({
              isKeystoreLockDialogOpen: false
            });
          }}
        />
        <KeystoreEditorDialog
          open={isKeystoreEditDialogOpen}
          keystore={keystore}
          doUpdateKeystore={doUpdateKeystore}
          onClose={() => {
            this.setState({
              isKeystoreEditDialogOpen: false
            });
          }}
        />
      </React.Fragment>
    );
  }
}

WebKeystore.propTypes = {
  keystore: PropTypes.object,
  doImportKeystore: PropTypes.func,
  doToggleKeystore: PropTypes.func
};

export default WebKeystore;
